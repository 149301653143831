<template>
  <Loader :value="promises.fetch">
    <div class="residentials-embed-page">
      <Residentials v-if="item.id" :project="item.id" :properties="properties" />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Residentials, { eventBus } from '@kvass/residentials/src/Component'

export default {
  computed: {
    ...mapState('Project', ['promises', 'item']),
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  created() {
    this.fetch()
    eventBus.on('click', item => {
      if (!window.parent) return
      window.parent.postMessage(
        {
          event: 'kvass:residentials:navigation',
          data: item,
        },
        '*',
      )
    })
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  metaInfo() {
    return {
      title: this.$tc('unit', 2),
      script: [{ src: '/iframe.resizer.contentwindow.min.js' }],
    }
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials-embed-page {
  .vue-make-lazy {
    min-height: 1px;
  }
}
</style>
